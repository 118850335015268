import './Profile.css'
import NavBar from '../Component/NavBar';
import Footer from '../Component/Footer';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import React, { useEffect, useState } from 'react';
import {
    useNavigate
  } from "react-router-dom";
  import axios from 'axios'
import withReactContent from 'sweetalert2-react-content'
import Swal from 'sweetalert2'
const Profile = () => {
    const MySwal = withReactContent(Swal)
    const navigate = useNavigate();
    const [clientSecret, setClientSecret] = useState('');
    const [firstname , setFirstname] = useState('');
    const [lastname , setLastname] = useState('');
    const [email , setEmail] = useState('');
    const [address , setAddress] = useState('');
    const [subDistrict , setSubDistrict] = useState('');
    const [district , setDistrict] = useState('');
    const [province , setProvince] = useState('');
    const [country , setCountry] = useState('');
    const [postalCode , setPostalCode] = useState('');
    const [phone , setPhone] = useState('');
    const [dataTable, setDataTable] = useState([]);
    const [dataTableProduct, setDataTableProduct] = useState([]);
    useEffect(() => {
        fetchUserStatus()
    },[])
    useEffect(() => {
        if (email != "") {
        getOrders()
        }
    },[email])
    const fetchUserStatus = async () =>{
        await axios.get('https://api.3dmodels.biz/user-specific',{headers: {Authorization: 'Bearer ' + localStorage.getItem('token')}})
        .then(async(response) => {
            if (response.data.status !== 'success') {
                navigate('/')
            }
            await axios.get(`https://api.3dmodels.biz/getuser?email=${response.data.data.email}`,{headers: {Authorization: 'Bearer ' + localStorage.getItem('token')}})
            .then((response) => {
            setFirstname(response.data.data[0].firstname);
            setLastname(response.data.data[0].lastname);
            setEmail(response.data.data[0].email);
            setPhone(response.data.data[0].phone);
            setAddress(response.data.data[0].address);
            setSubDistrict(response.data.data[0].subDistrict);
            setDistrict(response.data.data[0].district);
            setProvince(response.data.data[0].province);
            setCountry(response.data.data[0].country);
            setPostalCode(response.data.data[0].postalCode);})
        })
        .catch((error) => {
            console.log(error);
        })
         }
    const updateProfile = async () => {
        await axios.post('https://api.3dmodels.biz/updateUser', {
            firstname,
            lastname,
            email,
            phone,
            address,
            subDistrict,
            district,
            province,
            country,
            postalCode
        }).then((response) => {
            if (response.data.status == 'success') {
                alert('แก้ไขข้อมูลส่วนตัวสําเร็จ')
                fetchUserStatus()
            }
        })
    }
    const getOrders = async () => {
        let nameProduct = [];
        await axios.post('https://api.3dmodels.biz/getOrderUser', {
            email
        },{headers: {Authorization: 'Bearer ' + localStorage.getItem('token')}})
        .then(async(response) => {
            setDataTable(response.data.data)
            for (let index = 0; index < response.data.data.length; index++) {
                for (let index2 = 0; index2 < response.data.data[index].lineItems.length; index2++) {
                    await axios.get(`https://api.3dmodels.biz/getProductId?id=${response.data.data[index].lineItems[index2].price.product }`)
                    .then((response) => {
                        nameProduct.push(response.data.name)
                    })
                }
            }
    }).then(
        () => {
            setDataTableProduct(nameProduct)
        }
    )
}
    const moreInfo = async (order) => {
        await MySwal.fire({
            title: <p>รายละเอียดการสั่งซื้อ</p>,
            html: `<div>
                ${order.lineItems.map((item,index) => `<p>${index+1}. ${item.description} - ${item.amount_total/100}</p>`)}
            </div>`,
            confirmButtonColor: 'rgb(49, 145, 255)',
        })
    }
    const downloadProduct = async (name) => {
        await axios.post('https://api.3dmodels.biz/getProductemail', {
            name,
            email,
        },{
            responseType: 'blob'
        })
        .then((response) => {
            if (new Blob([response.data]).size == 46) {
                MySwal.fire({
                    title: <p>Product no longer available</p>,
                    confirmButtonColor: "rgb(0, 132, 255)",
                })
            }
            else{
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `${name}.glb`);
                document.body.appendChild(link);
                link.click();
            }
        })
    }
    return (
        <div className='Profile'>
            <NavBar/>
            <Container className='profile-container'>
                <Row>
                    <Col>
                        <div className='header-profile-name-block'>
                            <h3>User Name : {email}</h3>
                        </div>
                    </Col>
                </Row>
                <Row className='typ-data-profile-block text-name-typ input-style'>
                    <p>Your name</p>
                    <Col classname='all-typ-data-in-profile '>
                        <div className='typ-box-data'>
                            <input value={firstname} onChange={(e) => setFirstname(e.target.value)} type="text" placeholder="First name"/>
                        </div>
                    </Col>
                    <Col classname='all-typ-data-in-profile'>
                        <div className='typ-box-data '>
                            <input value={lastname} onChange={(e) => setLastname(e.target.value)} type="text" placeholder="Last name"/>
                        </div>
                    </Col>
                </Row>
                <Row className='typ-data-profile-block input-style'>
                    <Col classname='all-typ-data-in-profile'>
                        <div className='typ-box-data'>
                            <p>Address</p>
                            <input value={address} onChange={(e) => setAddress(e.target.value)} type="text" placeholder="house number,street.."/>
                        </div>
                    </Col>
                    <Col classname='all-typ-data-in-profile'>
                        <div className='typ-box-data'>
                            <p>Sub-district</p>
                            <input value={subDistrict} onChange={(e) => setSubDistrict(e.target.value)} type="text" placeholder="Your sub-district"/>
                        </div>
                    </Col>
                </Row>
                <Row className='typ-data-profile-block input-style'>
                    <Col classname='all-typ-data-in-profile'>
                        <div className='typ-box-data'>
                            <p>District</p>
                            <input value={district} onChange={(e) => setDistrict(e.target.value)} type="text" placeholder="Your district"/>
                        </div>
                    </Col>
                    <Col classname='all-typ-data-in-profile'>
                        <div className='typ-box-data'>
                            <p>Province</p>
                            <input value={province} onChange={(e) => setProvince(e.target.value)} type="text" placeholder="Your province"/>
                        </div>
                    </Col>
                </Row>
                <Row className='typ-data-profile-block input-style'>
                    <Col classname='all-typ-data-in-profile'>
                        <div className='typ-box-data'>
                            <p>Country</p>
                            <input value={country} onChange={(e) => setCountry(e.target.value)} type="text" placeholder="Your country"/>
                        </div>
                    </Col>
                    <Col classname='all-typ-data-in-profile'>
                        <div className='typ-box-data'>
                            <p>Post code</p>
                            <input value={postalCode} onChange={(e) => setPostalCode(e.target.value)} type="text" placeholder="Your post code"/>
                        </div>
                    </Col>
                </Row>
                <Row className='typ-data-profile-block tyb-phone-number'>
                    <Col classname='all-typ-data-in-profile'>
                        <div className='typ-box-data phone-num-data'>
                            <p>Phone number</p>
                            <div className='phone-box'>
                                <input value={phone} onChange={(e) => setPhone(e.target.value)} type="text" placeholder="Phone Number" />
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row className='typ-data-profile-block'>
                    <Col>
                        <div className='button-con'>
                            <button onClick={() => updateProfile()} className='button-continue'>Save Change</button>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <table className='table'>
                        <thead>
                            <tr>
                                <th colSpan={3}>Order ID</th>
                                <th colSpan={2}>Order Date</th>
                                <th colSpan={2}>Total</th>
                                <th colSpan={2}>Status</th>
                                <th colSpan={2}>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {dataTable.map((data, index) => {
                                return (
                                    <tr key={index+'dataTable'}>
                                        <td  colSpan={3}>{(data.id).slice(0, 20)+"..."}</td>
                                        <td colSpan={2}>{new Date(data.created*1000).toLocaleString()}</td>
                                        <td colSpan={2}>{data.lineItems.reduce((a, b) => a + b.amount_total/100, 0)}</td>
                                        <td colSpan={2}>Paid</td>
                                        <td colSpan={2}>
                                            <button onClick={() => moreInfo(data)} className='button-continue'>View</button>
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </Row>
                <Row>
                <table className='table'>
                        <thead>
                            <tr>
                                <th colSpan={2}>Name</th>
                                <th colSpan={2}>Order Date</th>
                            </tr>
                        </thead>
                        <tbody>
                            {[...new Set(dataTableProduct)].map((data, index) => {
                                return (
                                    <tr key={index+'dataTableProduct'}>
                                        <td colSpan={2}>{data}</td>
                                        <td colSpan={2}>
                                            <button onClick={() => downloadProduct(data)} className='button-continue'>Download</button>
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </Row>
            </Container>
            <Footer/>
        </div>

    )
}
export default Profile