import './AdminSale.css'
import NavBar from '../Component/AdminNavbar';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Button, Form } from 'react-bootstrap';
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import axios from 'axios'
import withReactContent from 'sweetalert2-react-content'
import Swal from 'sweetalert2'
const AdminSale = () => {
    const MySwal = withReactContent(Swal)
    const [selectedDateFrom, setSelectedDateFrom] = useState(new Date().getTime() - 24 * 60 * 60 * 1000);
    const [selectedDateTo, setSelectedDateTo] = useState(new Date().getTime());
    const [dataTable, setDataTable] = useState([]);
    useEffect(() => {
        fetchOrder()
    },[])
    const fetchOrder = async () => {
        await axios.post('https://api.3dmodels.biz/getOrderItem', {
            from: selectedDateFrom ,
            to: selectedDateTo
        })
        .then((response) => {
            console.log(response.data.data);
            setDataTable(response.data.data);
        })
    }
    const moreInfo = (item) => {
        console.log(item);
        MySwal.fire({  
            title: <p>{item.email}</p>,
            confirmButtonColor: "rgb(0, 132, 255)",
            html:`<div>${item.lineItems.map((item,index) => `<p>${index+1}. ${item.description} - ${item.amount_total/100}</p>`)}</div>`
        })
    }
    return (
        <div className='Admin_sale'>
                <NavBar/>
                        <Container className='Admin_sale-block'>
                        <DatePicker selected={selectedDateFrom} onChange={(date) => setSelectedDateFrom(new Date(date).getTime())} />
                        <DatePicker selected={selectedDateTo} onChange={(date) => setSelectedDateTo(new Date(date).getTime())} />
                        <Button onClick={() => fetchOrder()}>Submit</Button>
                        <table className='table'>
                            <thead>
                                <tr>
                                    <th colSpan={3}>Id</th>
                                    <th colSpan={2}>Date</th>
                                    <th colSpan={3}>Email</th>
                                    <th colSpan={2}>Price</th>
                                    <th colSpan={2}>More</th>
                                </tr>
                            </thead>
                            <tbody>
                                {dataTable.map((item, index) => {
                                    return (
                                        <tr>
                                            <td onClick={() => navigator.clipboard.writeText(item.id)} colSpan={3}>
                                            <svg viewBox="0 0 24 24" width="18" height="18" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M20.9983 10C20.9862 7.82497 20.8897 6.64706 20.1213 5.87868C19.2426 5 17.8284 5 15 5H12C9.17157 5 7.75736 5 6.87868 5.87868C6 6.75736 6 8.17157 6 11V16C6 18.8284 6 20.2426 6.87868 21.1213C7.75736 22 9.17157 22 12 22H15C17.8284 22 19.2426 22 20.1213 21.1213C21 20.2426 21 18.8284 21 16V15" stroke="#1C274C" stroke-width="1.5" stroke-linecap="round"></path> <path d="M3 10V16C3 17.6569 4.34315 19 6 19M18 5C18 3.34315 16.6569 2 15 2H11C7.22876 2 5.34315 2 4.17157 3.17157C3.51839 3.82475 3.22937 4.69989 3.10149 6" stroke="#1C274C" stroke-width="1.5" stroke-linecap="round"></path> </g></svg>
                                                {(item.id).slice(0, 20)+'...'}
                                            </td>
                                            <td colSpan={2}>{new Date(item.created * 1000).toLocaleString()}</td>
                                            <td colSpan={3}>{item.email}</td>
                                            <td colSpan={2}>{item.lineItems.reduce((accumulator, object) => accumulator + object.amount_total/100, 0)}</td>
                                            <td colSpan={2}><Button onClick={() => moreInfo(item)}>Detail</Button></td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                        </Container>
        </div>

 )
}
export default AdminSale