import './AdminProduct.css'
import React from 'react';
import AdminNavbar from '../Component/AdminNavbar';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Swal from 'sweetalert2'
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import withReactContent from 'sweetalert2-react-content'
import { useState, useEffect } from 'react';
import axios from 'axios'
import { useNavigate } from 'react-router-dom';
import ModelViewer from '../../3DProduct';
import { useRef } from 'react';
import html2canvas from 'html2canvas';
const AdminHome = () => {
    const [name, setName] = useState('');
    const [price, setPrice] = useState('');
    const [description, setDescription] = useState('');
    const [category, setCategory] = useState('');
    const [tag, setTag] = useState('');
    const [file, setFile] = useState('');
    const [modelfile, setModelFile] = useState('');
    const [products, setProducts] = useState([]);
    const [imageProduct, setImageProduct] = useState([]);
    const captureRef = useRef(null);
    const [capturedImage, setCapturedImage] = useState([]);
    const [cameraState, setCameraState] = useState(0);
    const navigate = useNavigate();
    const MySwal = withReactContent(Swal)
    useEffect(() => {
        axios.get('https://api.3dmodels.biz/auth-admin',{
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('token')
            }
        }).then((response) => {
            if (response.data.status !== 'success') {
                navigate('/')
            }
        })
        fetchProducts()
    },[])
    const actionMore = (id) => {
        const item = products.find(product => product.ID === id)
        MySwal.fire({
            title: <p>{name}</p>,
            confirmButtonColor: "rgb(0, 132, 255)",
            html: <div style={{display:'flex',justifyContent:'center',alignItems:'center',width:'100%',height:'100%',flexDirection:'column'}}><img src={item.Image} width='100%' height='100%' /><p>{item.Description}</p><p>{item.Price}</p><p>{item.Category}</p><p>{item.Tag}</p></div>,
        })
    }
    const actionEdit = (id) => {
        const item = products.find(product => product.ID === id)
        MySwal.fire({
            title: 'Edit',
            html:
              `<div style="margin:10px">Name</div>` +
              `<input id="input1" style="margin:0px" name="input1" class="swal2-input" value="${item.Name}">` +
              `<div style="margin:10px">Price</div>` +
              `<input id="input2" style="margin:0px" name="input2" class="swal2-input" value="${item.Price}">`+
              `<div style="margin:10px">Description</div>` +
              `<input id="input3"style="margin:0px"  name="input2" class="swal2-input" value="${item.Description}">`+
              `<div style="margin:10px">Category</div>` +
              `<input id="input4" style="margin:0px" name="input2" class="swal2-input" value="${item.Category}">`+
              `<div style="margin:10px">Tag</div>` +
              `<input id="input5" style="margin:0px" name="input3" class="swal2-input" value="${item.Tag}">`,
            focusConfirm: false,
            preConfirm: () => {
              return {
                input1: document.getElementById('input1').value,
                input2: document.getElementById('input2').value,
                input3: document.getElementById('input3').value,
                input4: document.getElementById('input4').value,
                input5: document.getElementById('input5').value,
              };
            },
          }).then(async(result) => {
              if(result.isConfirmed){
                await axios.post('https://api.3dmodels.biz/editProduct', {
                    id: item.ID,
                    name: result.value.input1,
                    price: result.value.input2,
                    description: result.value.input3,
                    category: result.value.input4,
                    tag: result.value.input5,
                }).then((response) => {
                    if(response.data.status === 'success'){
                        MySwal.fire({
                            title: <p>{item.Name}</p>,
                            confirmButtonColor: "rgb(0, 132, 255)",
                            text: "Product has been edited",
                        })
                        fetchProducts()
                    }
                })
              }
          })
    }
    const actionDelete = async(id) => {
        const item = products.find(product => product.ID === id)
        await axios.post('https://api.3dmodels.biz/deleteProduct', {
            id: item.ID
        }).then((response) => {
            if (response.data.status === 'success') {
                MySwal.fire({
                    title: <p>{item.Name}</p>,
                    confirmButtonColor: "rgb(0, 132, 255)",
                    text: "Product has been deleted",
                })
                fetchProducts()
            }
        })
    }
    const fetchProducts = async () => {
        await axios.get('https://api.3dmodels.biz/fetchProducts').then(async(response) => {
        console.log(response.data)
        let dataReturn = []
        await Promise.all(response.data.map(async(product) => {
            let temp = {}
            for(let i = 0; i < product.filenames.length; i++){
                const filename = product.filenames[i]
                const filenameExtension = filename.slice((filename.lastIndexOf(".") - 1 >>> 0) + 2)
                if(filenameExtension == 'jpg' || filenameExtension == 'jpeg' || filenameExtension == 'png'){
                    await axios.post('https://api.3dmodels.biz/getProduct', {
                        filename,
                    },{
                        responseType: 'blob'
                    }).then((response) => {
                        temp['Image'] = URL.createObjectURL(response.data)
                    })
                    break;
                }
            }
            temp['ID'] = product.id
            temp['Name'] = product.name
            temp['Price'] = product.price
            temp['Description'] = product.description
            temp['Category'] = product.category
            temp['Tag'] = product.tag
            dataReturn.push(temp)
        }))
        await console.log(dataReturn)
        await setProducts(dataReturn)
        }).catch((error) => {
            console.log(error)
        })
    }
    
    const captureImage = () => {
        if (captureRef.current) {
          html2canvas(captureRef.current)
            .then((canvas) => {
              const imageDataUrl = canvas.toDataURL('image/png');
              fetch(imageDataUrl)
                .then(res => res.blob())
                .then(
                  (blob) => {
                      setCapturedImage((capturedImages) => [...capturedImages, blob]);
                  }
                )
            })
            .catch((error) => {
              console.error('Error capturing image:', error);
            });
        }
      };
      
      
      
    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const formData = new FormData();
            formData.append('name', name);
            formData.append('price', price);
            formData.append('description', description);
            formData.append('category', category);
            formData.append('tag', tag);
            [...capturedImage, modelfile].forEach((file) => {
             if (file) {
               formData.append('files', file);
             }
           })
          await axios.post('https://api.3dmodels.biz/uploadproduct', formData, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          }).then(async(response) => {
              if(response.data.status == 'success'){
                await MySwal.fire({
                    title: <p>{name}</p>,
                    confirmButtonColor: "rgb(0, 132, 255)",
                    text: "Product has been added",
                })
                await setName('')
                await setPrice('')
                await setDescription('')
                await setCategory('')
                await setTag('')
                await setCapturedImage([])
                await fetchProducts()
              }
          })
        } catch (error) {
          console.error(error.response.data);
        }
      };
      const handleModel = (file) => {
        setFile(URL.createObjectURL(file))
        setModelFile(file)
      }
    return (
        <div className='AdminProduct'>
            <AdminNavbar/>
            <Container className='admin-product-con-1'>
                <Row>
                    <Col>
                        <h1>Products</h1>
                    </Col>
                </Row>
                <Row>
                    <Col>
                    <Form onSubmit={handleSubmit} className='form-register'>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label>Product name</Form.Label>
                                <Form.Control onChange={(e) => setName(e.target.value)} type="text" placeholder="Enter product name" />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label>Product price</Form.Label>
                                <Form.Control onChange={(e) => setPrice(e.target.value)} type="text" placeholder="Enter product price" />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label>Product description</Form.Label>
                                <Form.Control onChange={(e) => setDescription(e.target.value)} as="textarea" type="textarea" placeholder="Enter product description" />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label>Product category</Form.Label>
                                <Form.Control onChange={(e) => setCategory(e.target.value)} type="text" placeholder="Enter product category" />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label>Product tag</Form.Label>
                                <Form.Control onChange={(e) => setTag(e.target.value)} type="text" placeholder="Enter product tag" />
                            </Form.Group>
                            <Form.Group controlId="formFile" className="mb-3">
                                <Form.Label>Product file/image</Form.Label>
                                <Form.Control onChange={(e) => handleModel(e.target.files[0])} type="file" multiple />
                            </Form.Group>
                            <Button className='admin-product-button-submit' type="submit">Submit</Button>
                        </Form>
                    </Col>
                </Row>
                <Row className='admin-product-row-1'>
                    <Button onClick={captureImage}>Capture</Button>
                    {file && 
                    <div className='admin-product-div-1'>
                        <ModelViewer path={file} camera={cameraState} ref={captureRef} width='300px' height='300px' />
                    </div>
                    }
                    <Row className='admin-product-row-2'>
                    {capturedImage && capturedImage.map((image,index) => (
                        <Col key={index} sm={12} md={4}>
                            <img  src={URL.createObjectURL(image)} alt='img-1' className='admin-product-img-2'/>
                        </Col>
                    ))}
                    </Row>
                </Row>
            </Container>
            <Container className='admin-product-con-3'>
                <Row>
                    <Col className='admin-product-col-3'>
                    <table className='table-product'>
                        <thead>
                            <tr>
                                <th>Image</th>
                                <th>Name</th>
                                <th>Price</th>
                                <th>More</th>
                            </tr>
                        </thead>
                        <tbody>
                            {products.map((item,index) => (
                            <tr key={index}>
                                <td><img src={item.Image} alt={item.Name} className='admin-product-img-3'/></td>
                                <td>{item.Name}</td>
                                <td>{item.Price}</td>
                                <td>
                                    <Button onClick={() => actionMore(item.ID)}>More</Button>
                                    <Button onClick={() => actionEdit(item.ID)} variant='warning'>Edit</Button>
                                    <Button onClick={() => actionDelete(item.ID)} variant='danger'>Delete</Button>
                                </td>
                            </tr>
                            ))}
                        </tbody>
                        </table>
                    </Col>
                </Row>
            </Container>

        </div>
   );
};
export default AdminHome;
