import './Products.css'
import React from 'react';
import NavBar from '../Component/NavBar';
import Footer from '../Component/Footer';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Button } from 'react-bootstrap';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { useParams,useNavigate } from 'react-router-dom';
import { useEffect,useState } from 'react';
import axios from 'axios';
const Product = () => {
    const navigate = useNavigate();
    const MySwal = withReactContent(Swal)
    let { product } = useParams();
    const [products, setProducts] = useState([]);
    useEffect(() => {
        fetchProduct()
    },[product])
    const fetchProduct = async () =>{
        axios.get(`https://api.3dmodels.biz/fetchProduct?search=${product}`).then(async(response) => {
            let dataResponse = []
            await Promise.all(response.data.map(async(product) => {
                let temp = product
                for(let i = 0; i < product.filenames.length; i++){
                    const filename = product.filenames[i]
                    const filenameExtension = filename.slice((filename.lastIndexOf(".") - 1 >>> 0) + 2)
                    if(filenameExtension == 'jpg' || filenameExtension == 'jpeg' || filenameExtension == 'png'){
                        await axios.post('https://api.3dmodels.biz/getProduct', {
                            filename,
                        },{
                            responseType: 'blob'
                        }).then((response) => {
                            temp['Image'] = URL.createObjectURL(response.data)
                        })
                        break;
                    }
                }
                dataResponse.push(temp)
            }))
            await setProducts(dataResponse)
        })
    }
    const handleAdd = (product) => {
        if(localStorage.getItem('cart') == null){
            let cart = []
            cart.push(product)
            localStorage.setItem('cart',JSON.stringify(cart))
        }else{
            let cart = JSON.parse(localStorage.getItem('cart'))
            cart.push(product)
            localStorage.setItem('cart',JSON.stringify(cart))
        }
        MySwal.fire({
            title: <p style={{color:'rgb(49, 145, 255)'}}>Added to cart</p>,
            icon: 'success',
            showConfirmButton: false,
            timer: 1500
          })
    }
    return (
        <div className='Selectproduct'>
            <NavBar/>
            <Container>
                <Row>
                    <h2 style={{color:'rgb(49, 145, 255)',fontWeight:'bold'}}>Search result for '{product}' - {products.length > 0 ? products.length : 0} items</h2>
                </Row>
                <Row>
                    {
                        products.map((product , index) => {
                            return (
                                <Col sm={6} md={3} key={index}>
                                    <div className='card-product'>
                                        <div onClick={() => navigate(`/product/${product.id}`)} className='card-image'>
                                            <img className='card-product-img' src={product.Image} alt={product.Name}/>
                                        </div>
                                        <div onClick={() => navigate(`/product/${product.id}`)} className='card-content'>
                                            <h3>{product.name}</h3>
                                            <p>{product.description}</p>
                                        </div>
                                        <div className='card-action'>
                                            <Button onClick={() => handleAdd(product)}
                                            >Add to cart</Button>
                                        </div>
                                    </div>
                                </Col>
                            )
                        })
                    }
                </Row>
            </Container>
            <Footer/>
        </div>


   );
};
export default Product;
