import './AdminHome.css'
import React from 'react';
import AdminNavbar from '../Component/AdminNavbar';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Swal from 'sweetalert2'
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import withReactContent from 'sweetalert2-react-content'
import { useState, useEffect } from 'react';
import axios from 'axios'
import { useNavigate } from 'react-router-dom';
const AdminHome = () => {
    const navigate = useNavigate();
    const MySwal = withReactContent(Swal)
    useEffect(() => {
        axios.get('https://api.3dmodels.biz/auth-admin',{
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('token')
            }
        }).then((response) => {
            if (response.data.status !== 'success') {
                navigate('/')
            }
        })
    },[])
    return (
        <div className='Register-main'>
            <Container>
                <Row>
                    <Col className='register'>
                        <h1>Admin Home</h1>
                        <p>Admin Home</p>
                    </Col>
                </Row>
            </Container>
        </div>


   );
};
export default AdminHome;
