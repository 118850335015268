import './Checkout.css'
import NavBar from '../Component/NavBar';
import Footer from '../Component/Footer';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import React, { useEffect, useState } from 'react';
import {loadStripe} from '@stripe/stripe-js';
import {
  EmbeddedCheckoutProvider,
  EmbeddedCheckout
} from '@stripe/react-stripe-js';
import {
  useNavigate
} from "react-router-dom";
import axios from 'axios'
import { Button } from 'react-bootstrap';

const Checkout = () => { 
    const navigate = useNavigate();
    const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);
    const [clientSecret, setClientSecret] = useState('');
    const [firstname , setFirstname] = useState('');
    const [lastname , setLastname] = useState('');
    const [email , setEmail] = useState('');
    const [address , setAddress] = useState('');
    const [subDistrict , setSubDistrict] = useState('');
    const [district , setDistrict] = useState('');
    const [province , setProvince] = useState('');
    const [country , setCountry] = useState('');
    const [postalCode , setPostalCode] = useState('');
    const [phone , setPhone] = useState('');
    const [data, setData] = useState([]);
    const [total, setTotal] = useState(0);
    useEffect(() => {
        fetchUserStatus()
        getData()
    },[])
    const fetchUserStatus = async () =>{
         await axios.get('https://api.3dmodels.biz/user-specific',{headers: {Authorization: 'Bearer ' + localStorage.getItem('token')}})
         .then(async(response) => {
             if (response.data.status !== 'success') {
                 navigate('/')
             }
             await axios.get(`https://api.3dmodels.biz/getuser?email=${response.data.data.email}`,{headers: {Authorization: 'Bearer ' + localStorage.getItem('token')}})
             .then((response) => {
             setFirstname(response.data.data[0].firstname);
             setLastname(response.data.data[0].lastname);
             setEmail(response.data.data[0].email);
             setPhone(response.data.data[0].phone);
             setAddress(response.data.data[0].address);
             setSubDistrict(response.data.data[0].subDistrict);
             setDistrict(response.data.data[0].district);
             setProvince(response.data.data[0].province);
             setCountry(response.data.data[0].country);
             setPostalCode(response.data.data[0].postalCode);})
         })
         .catch((error) => {
             console.log(error);
         })
          }
    const getData = async () => {
        let dataReturn = {}
        JSON.parse(localStorage.getItem("cart"))?.map((item) => {
            if(!dataReturn[item.id]){
                dataReturn[item.id] = item
                dataReturn[item.id]['quantity'] = 1
                setTotal(total =>total + parseFloat(item.price)) 
            }
        })
        setData(Object.values(dataReturn))
        let dataResponse = []
        console.log(Object.values(dataReturn))
        await Promise.all(Object.values(dataReturn).map(async(product) => {
            let temp = product
            for(let i = 0; i < product.filenames.length; i++){
                const filename = product.filenames[i]
                const filenameExtension = filename.slice((filename.lastIndexOf(".") - 1 >>> 0) + 2)
                if(filenameExtension == 'jpg' || filenameExtension == 'jpeg' || filenameExtension == 'png'){
                    await axios.post('https://api.3dmodels.biz/getProduct', {
                        filename,
                    },{
                        responseType: 'blob'
                    }).then((response) => {
                        temp['Image'] = URL.createObjectURL(response.data)
                    })
                    break;
                }
            }
            dataResponse.push(temp)
        }))
        await setData(dataResponse)
    }
    const createPaymentIntent = async () => {
        const preresponse = await axios.post('https://api.3dmodels.biz/updateUser', {
            firstname,lastname,email,phone,address,subDistrict,district,province,country,postalCode
        })
        .then(async(res) => {
            if (res.data.status === 'success') {
                const response = await axios.post('https://api.3dmodels.biz/create-checkout-session', {
                    name : `${firstname} ${lastname}`,
                    email,
                    phone,
                    shipping : {
                        name : `${firstname} ${lastname}`,
                        address : {
                            line1: address,
                            line2: `${subDistrict} ${district} ${province} ${country} ${postalCode}`,
                        }
                    },
                    products : data
                },{headers: {Authorization: 'Bearer ' + localStorage.getItem('token')}})
                .then((response) => setClientSecret(response.data.clientSecret));
            }
        })
    };
    return (
        clientSecret ? (
            {clientSecret} ? (
                <EmbeddedCheckoutProvider
                  stripe={stripePromise}
                  options={{clientSecret}}
                >
                  <EmbeddedCheckout />
                </EmbeddedCheckoutProvider>
              ) : null
        ):(
        <div className='Checkout'>
            <NavBar/>
            <Container>
                <Row>
                    <Col>
                    <div className='text-checkout'>
                        <h1>Check out</h1>
                    </div>
                    </Col>
                </Row>
                <Row>
                    <Col sm={12} md={7}>
                    <Container>
                            <Row >
                                <Col>
                                    <div className='acc-ship-block'>
                                        <div className='text-accout-block'>
                                            <div className='header-accout-block'>
                                                <h3>Account</h3>
                                            </div>
                                            <div className='show-mail-accout-block'>
                                                <p>{email}</p>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                        <Container>
                            <Row >
                                <Col>
                                    <Container className='acc-ship-block'>
                                        <Row>
                                            <Col>
                                                <div className='header-shiping-block'>
                                                    <h3>Shiping Address</h3>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row className='typ-data-shiping-block text-name-typ input-style'>
                                            <p>Your name</p>
                                            <Col classname='all-typ-data-in-shiping'>
                                                <div className='typ-box-data'>
                                                    <input value={firstname} onChange={(e) => setFirstname(e.target.value)} type="text" placeholder="First name"/>
                                                </div>
                                            </Col>
                                            <Col classname='all-typ-data-in-shiping'>
                                                <div className='typ-box-data'>
                                                    <input value={lastname} onChange={(e) => setLastname(e.target.value)} type="text" placeholder="Last name"/>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row className='typ-data-shiping-block input-style'>
                                            <Col classname='all-typ-data-in-shiping'>
                                                <div className='typ-box-data'>
                                                    <p>Address</p>
                                                    <input value={address} onChange={(e) => setAddress(e.target.value)} type="text" placeholder="house number,street.."/>
                                                </div>
                                            </Col>
                                            <Col classname='all-typ-data-in-shiping'>
                                                <div className='typ-box-data'>
                                                    <p>Sub-district</p>
                                                    <input value={subDistrict} onChange={(e) => setSubDistrict(e.target.value)} type="text" placeholder="Your sub-district"/>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row className='typ-data-shiping-block input-style'>
                                            <Col classname='all-typ-data-in-shiping'>
                                                <div className='typ-box-data'>
                                                    <p>District</p>
                                                    <input value={district} onChange={(e) => setDistrict(e.target.value)} type="text" placeholder="Your district"/>
                                                </div>
                                            </Col>
                                            <Col classname='all-typ-data-in-shiping'>
                                                <div className='typ-box-data'>
                                                    <p>Province</p>
                                                    <input value={province} onChange={(e) => setProvince(e.target.value)} type="text" placeholder="Your province"/>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row className='typ-data-shiping-block input-style'>
                                            <Col classname='all-typ-data-in-shiping'>
                                                <div className='typ-box-data'>
                                                    <p>Country</p>
                                                    <input value={country} onChange={(e) => setCountry(e.target.value)} type="text" placeholder="Your country"/>
                                                </div>
                                            </Col>
                                            <Col classname='all-typ-data-in-shiping'>
                                                <div className='typ-box-data'>
                                                    <p>Post code</p>
                                                    <input value={postalCode} onChange={(e) => setPostalCode(e.target.value)} type="text" placeholder="Your post code"/>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row className='typ-data-shiping-block tyb-phone-number'>
                                            <Col classname='all-typ-data-in-shiping'>
                                                <div className='typ-box-data phone-num-data'>
                                                    <p>Phone number</p>
                                                    <div className='phone-box'>
                                                        <input value={phone} onChange={(e) => setPhone(e.target.value)} type="text" placeholder="Phone Number" />
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row className='typ-data-shiping-block'>
                                            <Col>
                                                <div className='button-con'>
                                                    <button  onClick={createPaymentIntent} className='button-continue'>Place order</button>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Container>
                                </Col>
                            </Row>
                        </Container>
                    </Col>
                    <Col sm={12} md={5}>
                        <Container>
                            <Row>
                                <Col sm={12} md={6}>
                                    <div className='order'>
                                        <div className='text-data-all'>
                                            <h2>Order summary</h2>
                                            <div className='text-all-data-in-product'>
                                                <div className='text-data-before-VAT'>
                                                    <p>Total Before VAT</p>
                                                    <p>฿ {Math.round(total / 1.07,2)}</p>
                                                </div>
                                                <div className='text-data-VAT'>
                                                    <p>VAT (7% included)</p>
                                                    <p>฿ {total}</p>
                                                </div>
                                                <div className='text-data-delivery'>
                                                    <p>delivery fee</p>
                                                    <p>฿ Free</p>
                                                </div>
                                                <div className='text-data-discout'>
                                                    <p>Discout</p>
                                                    <p>฿ 0</p>
                                                </div>
                                                <div className='text-data-price'>
                                                    <h3>Total</h3>
                                                    <h4>฿ {total}</h4>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={12} md={6}>
                                    <hr className="custom-hr"/>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={12} md={5}>
                                    <div className='item'>
                                        <Container >
                                            {data.map((item) => (
                                                    <Row key={item.ID}>
                                                        <Col><img style={{width:'100%',maxWidth:'200px'}} src={item.Image} alt={item.name} /></Col>
                                                        <Col>
                                                            <div>{item.name}</div>
                                                            <div>{item.price} ฿</div>
                                                        </Col>
                                                    </Row>
                                                ))}
                                        </Container>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </Col>
                </Row>
            </Container>
            <Footer/>
        </div>
        )
    )
}
export default Checkout