import './About.css'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import NavBar from '../Component/NavBar.js';
import Footer from '../Component/Footer.js';
import CarImage from '../images/CAR.png'


const About = () => {
    return (
        <div className='About'>
            <NavBar/>
                <Container >
                    <Row>
                        <Col>
                        <Container>
                            <Row className='about-main-banner-block'>
                                <Col sm={12} md={6}>
                                    <div className='about-text-block'>
                                        <h1>About us</h1>
                                        <h4>Welcome to 3D models.biz: Where Creativity Meets Reality</h4>
                                        <p> to 3DModels.biz.Here, we offer more than mere shapes—we provide the keys to unlock a universe where your grandest
                                            visions take form. Every file, every model is a brushstroke in a masterpiece yet to be realized,
                                            meticulously crafted to meet the needs of connoisseurs who understand that quality is an investment.
                                        </p>
                                    </div>
                                </Col>
                                <Col sm={12} md={6}>
                                    <div className='about-image-block'>
                                        <img className='img-block'src={CarImage} alt='img-1'></img>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                        <div className='All-block-out-magic'>
                            <div className='outstory-block'>
                                <h1>Our Story</h1>
                                <p>visions take form. Every file, every model is a brushstroke in a masterpiece yet to be realized,
                                   meticulously crafted to meet the needs of connoisseurs who understand that quality is an investment.
                                </p>
                            </div>
                            <div className='magic-block'>
                                <h1>The Magic of 3D Modeling</h1>
                                <p>visions take form. Every file, every model is a brushstroke in a masterpiece yet to be realized,
                                   meticulously crafted to meet the needs of connoisseurs who understand that quality is an investment.
                                </p>
                            </div>
                        </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12} md={6}>
                        <div className='All-block-mission-quality'>
                            <div className='ourmission-block'>
                                <h1>Out Mission</h1>
                                <p>meticulously crafted to meet the needs of connoisseurs who understand that quality is an
                                   meticulously crafted to meet the needs of connoisseurs who understand that quality is an
                                   meticulously crafted to meet the needs of connoisseurs who understand that quality is an
                                   meticulously crafted to meet the needs of connoisseurs who understand that quality is an
                                   meticulously crafted to meet the needs of connoisseurs who understand that quality is an
                                   meticulously crafted to meet the needs of connoisseurs who understand that quality is an
                                </p>
                            </div>
                        </div>
                        </Col>
                        <Col sm={12} md={6}>
                        <div className='All-block-mission-quality'>
                            <div className='quality-block'>
                                <h1>Queality and Accessibility</h1>
                                <p>meticulously crafted to meet the needs of connoisseurs who understand that quality is an
                                   meticulously crafted to meet the needs of connoisseurs who understand that quality is an
                                   meticulously crafted to meet the needs of connoisseurs who understand that quality is an
                                   meticulously crafted to meet the needs of connoisseurs who understand that quality is an
                                   meticulously crafted to meet the needs of connoisseurs who understand that quality is an
                                   meticulously crafted to meet the needs of connoisseurs who understand that quality is an
                                </p>
                            </div>
                        </div>
                        </Col>
                        </Row>
                        <Row>
                            <Col>
                            <Container className='about-con-last-block'>
                                <Row className='join-banner-block'>
                                    <Col sm={12} md={6}>
                                        <div className='join-text-block'>
                                            <h1>Join The 3DModels.biz</h1>
                                            <p> to 3DModels.biz.Here, we offer more than mere shapeswe provide the keys to unlock a universe where your grandest
                                                visions take form. Every file, every model is a brushstroke.
                                            </p>
                                        </div>
                                    </Col>
                                </Row>
                                <Row> 
                                    <Col >
                                        <div className='about-tag-all-block '>
                                            <button className='tag-block1 tag-bot'>Resources</button>
                                            <button className='tag-block2 tag-bot'>Inspiration</button>
                                            <button className='tag-block3 tag-bot'>Supportive Comunity</button>
                                        </div>
                                    </Col>
                                </Row>
                                    <Col>
                                        <div className='join-text-block last-text-block' >
                                            <p> to 3DModels.biz.Here, we offer more than mere shapeswe provide.</p>
                                        </div>
                                    </Col>
                            </Container>
                            </Col>
                    </Row>
                </Container>
            <Footer/>
        </div>
    )
}
export default About;