import './AdminManage.css'
import NavBar from '../Component/AdminNavbar';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useState,useEffect } from 'react';
import axios from 'axios';
import { Button } from 'react-bootstrap';
const AdminManage = () => {
    const [coupons, setCoupons] = useState('');
    const [discount, setDiscount] = useState(0);
    const [dataTable, setDataTable] = useState([]);
    useEffect(() => {
        getcoupons()
    },[])
    const getcoupons = async () => {
        await axios.get(`https://api.3dmodels.biz/getallcoupon`,{
            headers: {Authorization: 'Bearer ' + localStorage.getItem('token')}
        })
        .then((response) => {
            setDataTable(response.data.data)
        })
    }
    const addCoupons = async () => {
        await axios.post(`https://api.3dmodels.biz/addcoupon`, {
            couponCode : coupons,
            discount : discount
        }).then((response) => {
            if (response.data.status == 'success') {
                alert('เพิ่มคูปองส่วนลดสําเร็จ')
                getcoupons()
            }
        })
    }
    const deleteCoupons = async (couponCode) => {
        await axios.post(`https://api.3dmodels.biz/deletecoupon`, {
            couponCode : couponCode
        })
        .then((response) => {
            if (response.data.status == 'success') {
                alert('ลบคูปองส่วนลดสําเร็จ')
                getcoupons()
            }
        })
    }
    return (
        <div className='Admin_manage'>
            <NavBar/>
            <Container >
                <Row>
                    <Col >
                        <Container className='Admin_manage-block'>
                            <Row className='coupon'>
                                <Col md={6} sm={12} >
                                    <h5>คูปองส่วนลด</h5>
                                </Col>
                                <Col md={6} sm={12}>
                                    <input style={{width: '100%'}} onChange={(e) => setCoupons(e.target.value)} type='text' placeholder='promotions' />
                                </Col>
                            </Row>
                            <Row className='promotion'>
                                <Col md={6} sm={12}>                         
                                    <h5>ลดราคา</h5>
                                </Col>
                                <Col md={6} sm={12}>
                                    <input style={{width: '100%'}} onChange={(e) => setDiscount(e.target.value)} type='text' />
                                </Col>
                                <Col md={6} sm={12}>
                                    <button onClick={() => addCoupons()} className='botton-for-use'>ใช้คูปอง</button>
                                </Col>
                            </Row>
                        </Container>
                        <Row>
                            <Col >
                            <table className='manage-table'>
                                <thead>
                                    <tr>
                                        <th colSpan={3}>Coupon name</th>
                                        <th colSpan={2}>Discount</th>
                                        <th colSpan={2}>More</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {dataTable && dataTable.map((data,index) => {
                                        return (
                                            <tr>
                                                <td colSpan={3}>{data.couponCode}</td>
                                                <td colSpan={2}>{data.discount}</td>
                                                <td colSpan={2}><Button onClick={() => deleteCoupons(data.couponCode)} variant='danger'>ลบ</Button></td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}
export default AdminManage;