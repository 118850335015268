import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar, faStarHalfAlt } from '@fortawesome/free-solid-svg-icons';
import './StarRating.css';

const StarRating = ({ rating }) => {
  const stars = [];

  for (let i = 1; i <= 5; i++) {
    if (i <= rating) {
      stars.push(<FontAwesomeIcon key={i} icon={faStar} className="star filled" />);
    } else if (i - 0.5 <= rating) {
      stars.push(<FontAwesomeIcon key={i} icon={faStarHalfAlt} className="star half-filled" />);
    } else {
      stars.push(<FontAwesomeIcon key={i} icon={faStar} className="star" />);
    }
  }

  return <div className="star-rating">{stars}</div>;
};

export default StarRating;