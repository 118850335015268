import './Login.css'
import React from 'react';
import NavBar from '../Component/NavBar';
import Footer from '../Component/Footer';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Swal from 'sweetalert2'
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import withReactContent from 'sweetalert2-react-content'
import { useState , useEffect } from 'react';
import axios from 'axios'
import { useNavigate } from 'react-router-dom';
const Login = () => {
    const navigate = useNavigate();
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const MySwal = withReactContent(Swal)
    useEffect(() => {
        axios.get('https://api.3dmodels.biz/check-auth',{
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('token')
            }
        }).then((response) => {
            if (response.data.status === 'success') {
                navigate('/')
            }
        })
    },[])
    const submitlogin = (e) => {
        e.preventDefault();
        axios.post('https://api.3dmodels.biz/login', {
            email: email,
            password: password,
        })
        .then(async(response) => {
            if (response.data.status === 'error') {
                MySwal.fire({
                    title: <p>{response.data.message}</p>,
                    confirmButtonColor: "rgb(0, 132, 255)",
                })
            }
            else {
                await localStorage.setItem('token',response.data.token)
                await MySwal.fire({
                    title: <p>Login Success</p>,
                    confirmButtonColor: "rgb(0, 132, 255)",
                }) 
                await navigate('/')
            }
        })
    }
    return (
        <div className='Register-main'>
            <NavBar/>
            <Container>
                <Row>
                    <Col className='register'>
                        <h2>Login</h2>
                        <Form onSubmit={submitlogin} className='form-register'>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label>Email address</Form.Label>
                                <Form.Control onChange={(e) => setEmail(e.target.value)} type="email" placeholder="Enter email" />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formBasicPassword">
                                <Form.Label>Password</Form.Label>
                                <Form.Control onChange={(e) => setPassword(e.target.value)} type="password" placeholder="Password" />
                            </Form.Group>
                            <Button className='login-button-chekout' type="submit">Check out</Button>
                            <a>Not have an account<a href="/register">Register</a></a>
                        </Form>
                    </Col>
                </Row>
            </Container>
            <Footer/>
        </div>


   );
};
export default Login;
