import './Register.css'
import React from 'react';
import NavBar from '../Component/NavBar';
import Footer from '../Component/Footer';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Swal from 'sweetalert2'
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import withReactContent from 'sweetalert2-react-content'
import { useState, useEffect } from 'react';
import axios from 'axios'
import { useNavigate } from 'react-router-dom';
const Register = () => {
    const navigate = useNavigate();
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [repassword, setRePassword] = useState("");
    const MySwal = withReactContent(Swal)
    useEffect(() => {
        axios.get('https://api.3dmodels.biz/check-auth',{
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('token')
            }
        }).then((response) => {
            if (response.data.status === 'success') {
                navigate('/')
            }
        })
    },[])
    const submitregister = (e) => {
        e.preventDefault();
        if(password !== repassword){
            MySwal.fire({
                title: <p>Password not match</p>,
                confirmButtonColor: "rgb(0, 132, 255)",
            })
        }
        else{
            axios.post('https://api.3dmodels.biz/register', {
                name: name,
                email: email,
                password: password,
            })
            .then(async(response) => {
                if (response.data.status === 'error') {
                    MySwal.fire({
                        title: <p>{response.data.message}</p>,
                        confirmButtonColor: "rgb(0, 132, 255)",
                    })
                }
                else {
                    await MySwal.fire({
                        title: <p>Register Success</p>,
                        confirmButtonColor: "rgb(0, 132, 255)",
                    }) 
                    await navigate('/')
                }
            })
        }
    }
    return (
        <div className='Register-main'>
            <NavBar/>
            <Container>
                <Row>
                    <Col className='register'>
                        <h2>Create Account</h2>
                        <Form onSubmit={submitregister} className='form-register'>
                            <Form.Group style={{width:'80%'}} className="mb-3" controlId="formBasicEmail">
                                <Form.Label>Name</Form.Label>
                                <Form.Control onChange={(e) => setName(e.target.value)} type="text" placeholder="Enter name" />
                            </Form.Group>
                            <Form.Group style={{width:'80%'}} className="mb-3" controlId="formBasicEmail">
                                <Form.Label>Email address</Form.Label>
                                <Form.Control onChange={(e) => setEmail(e.target.value)} type="email" placeholder="Enter email" />
                            </Form.Group>
                            <Form.Group style={{width:'80%'}} className="mb-3" controlId="formBasicPassword">
                                <Form.Label>Password</Form.Label>
                                <Form.Control onChange={(e) => setPassword(e.target.value)} type="password" placeholder="Password" />
                            </Form.Group>
                            <Form.Group style={{width:'80%'}} className="mb-3" controlId="formBasicPassword">
                                <Form.Label>Re-enter password</Form.Label>
                                <Form.Control onChange={(e) => setRePassword(e.target.value)} type="password" placeholder="Password" />
                            </Form.Group>
                            <Button style={{width:'80%'}} type="submit">Check out</Button>
                            <p>Already have an account? <a href='/login'>Login</a></p>
                        </Form>
                    </Col>
                </Row>
            </Container>
            <Footer/>
        </div>


   );
};
export default Register;
