import React from "react";
import "./Navbar.css";
import { useState,useEffect } from "react";
import Container from 'react-bootstrap/Container';
import logob from '../images/Logobrand.png'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useNavigate } from "react-router-dom";
import axios from 'axios'
const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [search, setSearch] = useState('');
  const [choice, setChoice] = useState('');
  const navigate = useNavigate();
  const toggleNavbar = () => {
    setIsOpen(!isOpen);
  };
  const SearchItem = () => {
    navigate(`/products/${search}`)
  }
  useEffect(() => {
    axios.get(`https://api.3dmodels.biz/user-specific`,{
        headers: {Authorization: 'Bearer ' + localStorage.getItem('token')}
  }).then((response) => {
    if(response.data.status ==='success'){
      if(response.data.data.role === 'admin'){
          setChoice([
            {
              name: 'Admin',
              link: '/admin/sale'
            },
            {
              name: 'Profile',
              link: '/profile'
            }
          ])
      }
      if(response.data.data.role === 'user'){
          setChoice([
            {
              name: 'Profile',
              link: '/profile'
            }
          ])
        }
    }
    else{
      setChoice([
        {
          name: 'Sign In',
          link: '/login'
        }
      ])
    }
  })
},[])
  return (
    <div>
    <div className="Navbar">
      <Container className="top-navbar">
        <Row>
          <Col className="nav-col-1">
            <div className="pic-logo-brand">
              <img className="pic-logo" src={logob} alt='logo'></img>
            </div>
            <div className="brand-name">
              3D Models.biz
            </div>
          </Col>
          <Col className="nav-col-1">
            <div className="search-box">
              <input onChange={(e) => setSearch(e.target.value)} type="text" placeholder="Search"/>
              <button onClick={SearchItem} type="submit"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-search" viewBox="0 0 16 16">
                <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001q.044.06.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1 1 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0"/>
              </svg>
              </button>
            </div>
          </Col>
          <Col className="nav-col-1">
            <div className="sign-up">
              <svg style={{marginRight:"5px", marginBottom:"3px"}} xmlns="http://www.w3.org/2000/svg" width="20" height="20"  fill="rgb(8, 152, 255)" class="bi bi-person-circle" viewBox="0 0 16 16">
                <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0"/>
                <path fill-rule="evenodd" d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8m8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1"/>
              </svg>
              {choice[0] ? <a href={choice[0].link} style={{textDecoration:"none"}}>{choice[0].name}</a> : <a href='/login' style={{textDecoration:"none"}}>Sign In</a>}
            </div>
          </Col>
          <Col className="nav-col-1">
            <div className="cart">
            <svg style={{marginRight:"5px", marginBottom:"3px"}} xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-cart2" viewBox="0 0 16 16">
              <path d="M0 2.5A.5.5 0 0 1 .5 2H2a.5.5 0 0 1 .485.379L2.89 4H14.5a.5.5 0 0 1 .485.621l-1.5 6A.5.5 0 0 1 13 11H4a.5.5 0 0 1-.485-.379L1.61 3H.5a.5.5 0 0 1-.5-.5M3.14 5l1.25 5h8.22l1.25-5zM5 13a1 1 0 1 0 0 2 1 1 0 0 0 0-2m-2 1a2 2 0 1 1 4 0 2 2 0 0 1-4 0m9-1a1 1 0 1 0 0 2 1 1 0 0 0 0-2m-2 1a2 2 0 1 1 4 0 2 2 0 0 1-4 0"/>
            </svg>
              <a href="/cart" style={{textDecoration:"none"}}>Cart</a>
              </div>
          </Col>
        </Row>
      </Container>
      <Container className="nav-con-2">
        <Row className="nav-links">
          <Col className="nav-col-2">
            <a href="/">Home</a>
          </Col>
          <Col className="nav-col-2">
          <a href="/customModels">Custom Models</a>
          </Col>
          <Col className="nav-col-2">
            <a href="/about">About Us</a>
          </Col>
          <Col className="nav-col-2">
            <a href="/contact">Contact</a>
          </Col>
        </Row>
      </Container>
    </div>
    <Container fluid className="mobile-navbar">
      <Row style={{position:"relative"}}>
        <Col style={{position:"absolute",zIndex:"0"}} xs={9} className="nav-col-3">
              <div className="pic-logo-brand">
                <img className="pic-logo" src={logob} alt='logo'></img>
              </div>
              <div className="brand-name">
                3D Models.biz
              </div>
        </Col>
        <Col style={{zIndex:"1"}} xs={2} className="menu-icon" onClick={toggleNavbar}>
          {isOpen ? <div>
            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="bi bi-x" viewBox="0 0 16 16">
              <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708"/>
            </svg></div> : <div><svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="bi bi-list" viewBox="0 0 16 16">
            <path fill-rule="evenodd" d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5"/>
          </svg></div>}
        </Col>
      </Row>
      <Row>
        <Col className="nav-col-4">
          <div className="search-box">
              <input className="nav-input-3" onChange={(e) => setSearch(e.target.value)} type="text" placeholder="Search"/>
              <button onClick={SearchItem} type="submit"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-search" viewBox="0 0 16 16">
                <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001q.044.06.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1 1 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0"/>
              </svg>
              </button>
            </div>
        </Col>
      </Row>
      {isOpen && (
        <Row className="nav-row-4">
          <Col>
            <ul className="nav-links-mobile">
              <li><a href="/">Home</a></li>
              <li><a href="/about">About</a></li>
              <li><a href="/service">Services</a></li>
              <li><a href="/contact">Contact</a></li>
            </ul>
          </Col>
        </Row>
      )}
    </Container>
    </div>
  );
};

export default Navbar;