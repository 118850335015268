import './Cart.css'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import NavBar from '../Component/NavBar.js';
import Footer from '../Component/Footer.js';
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from 'axios';
import { Button } from 'react-bootstrap';
import { useNavigate } from "react-router-dom";
const Cart = () => {
    const navigate = useNavigate();
    const [code, setCode] = useState("");
    const [data, setData] = useState([]);
    const [total, setTotal] = useState(0);
    const handleChange = (event) => {
        setCode(event.target.value);
    };
    const getData = async () => {
        let dataReturn = {}
        JSON.parse(localStorage.getItem("cart"))?.map((item) => {
            if(dataReturn[item.id]){
                dataReturn[item.id]['amount'] += 1
            }
            else{
                dataReturn[item.id] = item
                dataReturn[item.id]['amount'] = 1
                setTotal(total => total + parseFloat(item.price))
            }
        })
        setData(Object.values(dataReturn))
        let dataResponse = []
        await Promise.all(Object.values(dataReturn).map(async(product) => {
            let temp = product
            for(let i = 0; i < product.filenames.length; i++){
                const filename = product.filenames[i]
                const filenameExtension = filename.slice((filename.lastIndexOf(".") - 1 >>> 0) + 2)
                if(filenameExtension == 'jpg' || filenameExtension == 'jpeg' || filenameExtension == 'png'){
                    await axios.post('https://api.3dmodels.biz/getProduct', {
                        filename,
                    },{
                        responseType: 'blob'
                    }).then((response) => {
                        temp['Image'] = URL.createObjectURL(response.data)
                    })
                    break;
                }
            }
            dataResponse.push(temp)
        }))
        await setData(dataResponse)
    }
    useEffect(() => {
        getData()
    },[])
    return (
        <div className='Cart'>
            <NavBar/>
            <Container>
                <Row>
                    <Row>
                        <Col>
                        <div className='text-in-cart'>
                            <div className='text-cart'>
                                <p>Cart</p>
                            </div>
                        </div>
                        </Col>
                    </Row>
                    <Col>
                    <div className='block-show-data-product'>
                    <table  className="table-cart">
                        <thead>
                            <tr>
                            <th>Product</th>
                            <th>Name</th>
                            <th>Price</th>
                            <th>Delete</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data.map((item) => (
                            <tr key={item.ID}>
                                <td><img style={{width:'100%',maxWidth:'200px'}} src={item.Image} alt={item.name} /></td>
                                <td>{item.name}</td>
                                <td>{item.price}</td>
                                <td><Button onClick={() => {localStorage.setItem('cart',JSON.stringify(JSON.parse(localStorage.getItem('cart')).filter((product) => product.id != item.id)));window.location.reload()}} variant="danger">Delete</Button></td>
                            </tr>
                            ))}
                        </tbody>
                    </table>
                    </div>
                    </Col>
                    <Col sm={12} md={5}>
                        <div className='box-data'>
                            <div className='text-in-block'>
                                <h1>Discount code </h1>
                                <div>
                                    <input className='input-code-cart' type="text" value={code} onChange={handleChange} placeholder='Your code' />
                                </div>
                            </div>
                            <div className='text-zone-block'>
                                <div className='zone-text-detail'>
                                    <div className='text-Before'>
                                        <p>Before VAT</p>
                                        <div>
                                            <p>{total / 1.07} THB</p>
                                        </div>
                                    </div>
                                    <div className='text-VAT'>
                                        <p>VAT (7% included)</p>
                                        <p>{total} THB</p>
                                    </div>
                                    <div className='price-product'>
                                        <div className='price'>
                                            <h2>{total} THB</h2>
                                        </div>
                                    </div>
                                </div>
                                
                                <div className='button-all-checkout'>
                                    <button onClick={() => navigate('/checkout')} className='button-checkout'>Check out</button>
                                </div>
                                <div className='Link-con'>
                                    <a href="/" className='link-continue'>Continue Shoping</a>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
            <Footer/>
        </div>
  )
}
export default Cart;