import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import Home from './Home';
import About from './About/About'
import Cart from './Cart/Cart'
import Product from './Product/Product';
import Contact from './Contact/Contact';
import Profile from './Profile/Profile';
import AdminSale from './Admin/Sale/AdminSale';
import Checkout from './Checkout/Checkout';
import Products from './Products/Products';
import CustomModels from './CustomModels/CustomModels';
import AdminManage from './Admin/Manage/AdminManage';
import Register from './Register/Register';
import Login from './Login/Login';
import Return from './Return/Return';
import AdminHome from './Admin/Home/AdminHome';
import AdminProduct from './Admin/Product/AdminProduct';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Routes, Route } from "react-router-dom";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
      <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/product/:product_id" element={<Product />} />
          <Route path="/products/:product" element={<Products />} />
          <Route path="/register" element={<Register />} />
          <Route path="/login" element={<Login />} />
          <Route path="/admin/home" element={<AdminHome />} />
          <Route path="/admin/product" element={<AdminProduct />} />
          <Route path="/Cart" element={<Cart />} />
          <Route path="/About" element={<About />} />
          <Route path="/Contact" element={<Contact />} />
          <Route path="/Checkout" element={<Checkout />} />
          <Route path="/Return" element={<Return />} />
          <Route path="/Profile" element={<Profile />} />
          <Route path="/admin/sale" element={<AdminSale />} />
          <Route path="/admin/manage" element={<AdminManage />} />
          <Route path="/Custommodels" element={<CustomModels />} />
      </Routes>
    </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
