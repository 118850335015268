import './Product.css'
import React from 'react';
import NavBar from '../Component/NavBar';
import Footer from '../Component/Footer';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import b1 from '../images/b1.png';
import Slider from "react-slick";
import CarImage from '../images/CAR.png'
import { Button } from 'react-bootstrap';
import StarRating from './StarRating';
import ModelViewer from '../3Drender';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { useParams } from 'react-router-dom';
import { useEffect,useState } from 'react';
import axios from 'axios';
import { MagnifyingGlass } from 'react-loader-spinner';
import { useNavigate } from 'react-router-dom';
const Product = () => {
    const navigate = useNavigate();
    const { product_id } = useParams();
    const [product, setProduct] = useState({});
    const [loading , setLoading] = useState(true)
    const [show3D , setShow3D] = useState(false)
    const MySwal = withReactContent(Swal)
    useEffect(() => {
        axios.post(`https://api.3dmodels.biz/getIdProduct`,{
            id:product_id,
        }).then(async(response) => {
            setProduct(response.data)
            console.log(response.data.data)
            let dataResponse = response.data.data
            let temp_image = []
            let temp_3D = []
            await setLoading(true)
            for(let i = 0; i < response.data.data.filenames.length; i++){
                const filename = response.data.data.filenames[i]
                const filenameExtension = filename.slice((filename.lastIndexOf(".") - 1 >>> 0) + 2)
                if(filenameExtension == 'jpg' || filenameExtension == 'jpeg' || filenameExtension == 'png'){
                    await axios.post('https://api.3dmodels.biz/getProduct', {
                        filename,
                    },{
                        responseType: 'blob'
                    }).then((response) => {
                        temp_image.push(URL.createObjectURL(response.data))
                    })
                }
                if(filenameExtension == 'glb'){
                    await axios.post('https://api.3dmodels.biz/getProduct', {
                        filename,
                    },{
                        responseType: 'blob'
                    }).then((response) => {
                        temp_3D.push(URL.createObjectURL(response.data))
                    })
                }
            }
            dataResponse['Image'] = temp_image
            dataResponse['3D'] = temp_3D
            await setProduct(dataResponse)
            await console.log(dataResponse)
            await setLoading(false)
        })
    },[])
    const handleAdd = (product) => {
        if(localStorage.getItem('cart') == null){
            let cart = []
            cart.push(product)
            localStorage.setItem('cart',JSON.stringify(cart))
        }else{
            let cart = JSON.parse(localStorage.getItem('cart'))
            cart.push(product)
            localStorage.setItem('cart',JSON.stringify(cart))
        }
        MySwal.fire({
            title: <p style={{color:'rgb(49, 145, 255)'}}>Added to cart</p>,
            icon: 'success',
            showConfirmButton: false,
            timer: 1500
          })
    }
    const get3D = () => {
        MySwal.fire({
            title: <p>3D Model</p>,
            html: <div style={{display:'flex',justifyContent:'center',alignItems:'center',width:'100%',height:'100%'}}><ModelViewer path={product['3D'][0]} width='100%' height='100%' /></div>,
            confirmButtonColor: "rgb(0, 132, 255)",
        })
    }
    return (
        <div className='Selectproduct'>
            {show3D ? <div style={{position:'fixed',top:'50%',left:'50%',transform:'translate(-50%,-50%)',zIndex:'1000',display:'flex',justifyContent:'center',alignItems:'center',width:'100%',height:'100%',backgroundColor:'rgba(0,0,0,0.5)',borderRadius:'10px',flexDirection:'column'}}>
                <Button style={{margin:'10px'}} onClick={() => setShow3D(false)}>Close</Button>
                <ModelViewer path={product['3D'][0]} width='100%' height='100%' />
                </div> : null}
            <NavBar/>
            {loading ? <div style={{display:'flex',justifyContent:'center',alignItems:'center',width:'100%',height:'60vh'}}><MagnifyingGlass
                                visible={true}
                                height="80"
                                width="80"
                                ariaLabel="magnifying-glass-loading"
                                wrapperStyle={{}}
                                wrapperClass="magnifying-glass-wrapper"
                                glassColor="#c0efff"
                                color="#e15b64"
                                /></div> :<Container>
                <Row>
                    <Col sm={12} md={7} className='block-all-show-product'>
                            <Slider dots={true} infinite={true} speed={500}>
                                {product['Image'] &&product['Image'].map((image,index) => {
                                    return (
                                        <div className='item-product'>
                                            <img className='product-img-block'src={image} alt='img-1'></img>
                                            <button onClick={() => setShow3D(true)} className='product-button-view3d'>3D view</button>
                                        </div>
                                    )
                                })}
                            </Slider>
                    </Col>
                    <Col sm={12} md={5}>
                        <div className='product-box-data'>
                            <div className='text-in-block'>
                                <h1>{product['name']} </h1>
                                <p>{product['description']}</p>
                                <StarRating rating={5} />
                                <div className='price-product'>
                                    <div className='price'>
                                        <h2>{product['price']} THB</h2>
                                    </div>
                                </div>
                                <div className='product-button-all-for-buy'>
                                    <button onClick={async() => {await handleAdd(product);await navigate('/cart')}} className='product-button-for-buy'>BUY NOW</button>
                                    <button onClick={() => handleAdd(product)} className='product-button-for-buy cart-for-buy'>ADD to Cart</button> 
                                </div>
                            </div>
                            <div className='text-type-all-block'>
                                <div className='product-text-type'>
                                    <h3>Type:</h3>
                                    <p>{product['category']}</p>
                                </div>
                            </div>
                            <div className='product-tag-all'>
                                <div className='product-text-type'>
                                    <h3>Tag:</h3>
                                </div>
                                <div className='product-tag-all-block'>
                                    <button className='product-tag-block1 product-tag-id'>{product['tag']}</button>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>}
            <Footer/>
        </div>


   );
};
export default Product;
