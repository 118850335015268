import React, { useRef, useEffect, useState,forwardRef } from 'react';
import { Canvas, useThree, extend } from '@react-three/fiber';
import { useGLTF, OrbitControls } from '@react-three/drei';
import * as THREE from 'three';
// Extend useGLTF to ensure it's efficiently reused
extend({ useGLTF });

function Model({ path }) {
  const gltf = useGLTF(path);
  const model = useRef();
  const { scene } = gltf;

  useEffect(() => {
    // Reset model position and scale for every new path
    model.current.position.set(0, 0, 0);
    model.current.scale.set(1, 1, 1);

    const box = new THREE.Box3().setFromObject(scene);
    const center = box.getCenter(new THREE.Vector3());
    const size = box.getSize(new THREE.Vector3());
    const maxDimension = Math.max(size.x, size.y, size.z);
    const scale = 5.0 / maxDimension; // Adjust this value to control the scale
    model.current.scale.set(scale, scale, scale);
    model.current.position.sub(center.multiplyScalar(scale));
  }, [path]); // Re-run this effect if the path changes

  return <primitive ref={model} object={scene} />;
}

const ModelViewer = forwardRef(({ path, width, height }, ref) => {
  useEffect(() => {
    const renderer = ref?.current?.canvas?.getBoundingClientRect()?.width
      ? new THREE.WebGLRenderer({ preserveDrawingBuffer: true })
      : null;

    if (renderer) {
      ref.current.setCanvas({ renderer });
    }
  }, [ref]);
  return (
    <div ref={ref} style={{ width, height ,display:'flex',justifyContent:'center',alignItems:'center'}}>
      <Canvas gl={{ preserveDrawingBuffer: true }}>
        <ambientLight intensity={5} />
        <spotLight position={[10, 10, 10]} angle={0.15} penumbra={1} />
        <Model path={path} />
        <OrbitControls />
      </Canvas>
    </div>
  );
});
export default ModelViewer;