import './Return.css'
import React from 'react';
import NavBar from '../Component/NavBar';
import Footer from '../Component/Footer';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Swal from 'sweetalert2'
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import withReactContent from 'sweetalert2-react-content'
import { useState, useEffect } from 'react';
import axios from 'axios'
import { useNavigate } from 'react-router-dom';
const Return = () => {
    const [status, setStatus] = useState(null);
    const [customerEmail, setCustomerEmail] = useState('');
    const navigate = useNavigate();
    useEffect(() => {
        const fetchSessionStatus = async () => {
            const queryString = window.location.search;
            const urlParams = new URLSearchParams(queryString);
            const sessionId = urlParams.get('session_id');
            await axios.get(`https://api.3dmodels.biz/session-status?session_id=${sessionId}`)
                .then((response) => {
                setStatus(response.data.status);
                setCustomerEmail(response.data.customer_email);
                });
        }
        fetchSessionStatus();
        localStorage.removeItem('cart');
    }, []);
  
    if (status === 'open') {
      return (
        navigate('/checkout')
      )
    }
  
    if (status === 'complete') {
      return (
        <section className="success">
            <svg xmlns="http://www.w3.org/2000/svg" width="100" height="100" fill="currentColor" class="bi bi-check2-all" viewBox="0 0 16 16">
            <path d="M12.354 4.354a.5.5 0 0 0-.708-.708L5 10.293 1.854 7.146a.5.5 0 1 0-.708.708l3.5 3.5a.5.5 0 0 0 .708 0zm-4.208 7-.896-.897.707-.707.543.543 6.646-6.647a.5.5 0 0 1 .708.708l-7 7a.5.5 0 0 1-.708 0"/>
            <path d="m5.354 7.146.896.897-.707.707-.897-.896a.5.5 0 1 1 .708-.708"/>
            </svg>
          <p>
            We appreciate your business! A confirmation email will be sent to {customerEmail}.
  
            If you have any questions, please email <a href="mailto:orders@example.com">orders@example.com</a>.
          </p>
          <div>
          <Button onClick={() => navigate('/')}>Back to Home</Button>
          <Button onClick={() => navigate('/profile')}>Go to download model</Button>
            </div>
        </section>
      )
    }
  
    return null;
  }
  
export default Return;
