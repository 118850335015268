import './CustomModels.css'
import NavBar from '../Component/NavBar';
import Footer from '../Component/Footer';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import CarImage from '../images/CAR.png'


const Custom_models = () => {
    return (
        <div className='Custom_models'>
            <NavBar/>
            <Container>
                <Row>
                    <Col>
                        <Container>
                            <Row className='custom-main-banner-block'>
                            <Col sm={12} md={6}>
                                <div className='custom-image-block'>
                                    <img className='img-block'src={CarImage} alt='img-1'></img>
                                </div>
                            </Col>
                            <Col sm={12} md={6}>
                                <div className='custom-text-block'>
                                    <h1>3D Custome Your Imagination</h1>
                                    <p> to 3DModels.biz.Here, we offer more than mere shapes—we provide the keys to unlock a universe where your grandest
                                        visions take form. Every file, every model is a brushstroke in a masterpiece yet to be realized,
                                        meticulously crafted to meet the needs of connoisseurs.
                                    </p>
                                    <button className='custom-button-block'>Customizing Now</button>
                                </div>
                            </Col>
                                <div >
                                </div>
                            </Row>
                        </Container>
                    </Col>
                </Row>
            </Container>
            <Footer/>
        </div>
    )
}

export default Custom_models