import './Contact.css'
import NavBar from '../Component/NavBar';
import Footer from '../Component/Footer';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import React, { useState, useRef } from 'react';
import emailjs from '@emailjs/browser';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
const Contact = () => {
    const form = useRef();
    const MySwal = withReactContent(Swal)
    const sendEmail = (e) => {
        e.preventDefault();
        emailjs
          .sendForm('service_7e2lffg', 'template_17zmhew', form.current, {
            publicKey: 'OeaRBEo3dhX9mMZd_',
          })
          .then(
            () => {
              MySwal.fire({
                icon: 'success',
                title: 'Your message has been sent. Thank you!',
                showConfirmButton: false,
                timer: 1500
              })
              form.current.reset();
            },
            (error) => {
              MySwal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Something went wrong!',
              })
            },
          );
      };
    return (
            <div className='Contact'>
                <NavBar/>
                    <Container>
                        <Row>
                            <Col>
                                <div className='text-name-contactus'>
                                    <h1>Contact Us</h1>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={12} md={6}>
                                <Container className='typ-data-all'>
                                    <form ref={form} onSubmit={sendEmail}>
                                    <Row>
                                        <Col>
                                            <div className='data-from-typ'>
                                                <p>Name</p>
                                                <input name="user_name" type='text' placeholder='First and Last name' />
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <div className='data-from-typ'>
                                                <p>Email</p>
                                                <input name="user_email" type='text' placeholder='Your email' />
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <div className='data-from-typ'>
                                                <p>Message</p>
                                                <input name="message" type='textarea' className='button-name-message' placeholder='Hello, i would like to...' />
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <div className='data-for-typ'>
                                                <button type='submit' className='button-send'>Send</button>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <div className='data-map'>
                                                <h1>Maps</h1>
                                            </div>
                                        </Col>
                                    </Row>
                                    </form>
                                </Container>
                            </Col>
                            <Col sm={12} md={6}>
                                <div className='all-data-right-page typ-data-all'>
                                    <div className='data-right-page data-right-name-address'>
                                        <p>Address</p>
                                        <p>123/43 Sanam Luang</p>
                                    </div>
                                    <div className='data-right-page data-right-name-email'>
                                        <p>Email</p>
                                        <p>sample@example.com</p>
                                    </div>
                                    <div className='data-right-page data-right-name-phone'>
                                        <p>Phone</p>
                                        <p>+66 0900000003</p>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <div className='maping-show'>
                                    <iframe
                                        width="100%" 
                                        height="500" 
                                        frameborder="0" 
                                        scrolling="no" 
                                        marginheight="0" 
                                        marginwidth="0" 
                                        src="https://maps.google.com/maps?q='Sanam Luang','Sanam Luang'&hl=es&z=14&amp;output=embed">
                                    </iframe>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                <Footer/>
            </div>
    )
}
export default Contact