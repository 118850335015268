import React, { useRef, useEffect, useState, forwardRef } from 'react';
import { Canvas, useThree } from '@react-three/fiber';
import { useGLTF, OrbitControls } from '@react-three/drei';
import * as THREE from 'three';
import { extend } from '@react-three/fiber';
// Extend useGLTF to ensure it's efficiently reused
extend({ useGLTF });

function Model({ path }) {
  const gltf = useGLTF(path);
  const model = useRef();
  const { scene } = gltf;

  useEffect(() => {
    model.current.position.set(0, 0, 0);
    model.current.scale.set(1, 1, 1);

    const box = new THREE.Box3().setFromObject(scene);
    const center = box.getCenter(new THREE.Vector3());
    const size = box.getSize(new THREE.Vector3());
    const maxDimension = Math.max(size.x, size.y, size.z);
    const scale = 5.0 / maxDimension; // Adjust this value to control the scale
    model.current.scale.set(scale, scale, scale);
    model.current.position.sub(center.multiplyScalar(scale));
  }, [path]);

  return <primitive ref={model} object={scene} />;
}

const ModelViewer = forwardRef(({ path, width, height, camera }, ref) => {
  const [cameraPosition, setCameraPosition] = useState([0, 0, 5]);

  useEffect(() => {
    switch (camera) {
      case 1: // Front
        setCameraPosition([0, 0, 5]);
        break;
      case 2: // Left
        setCameraPosition([-5, 0, 0]);
        break;
      case 3: // Right
        setCameraPosition([5, 0, 0]);
        break;
      case 4: // Back
        setCameraPosition([0, 0, -5]);
        break;
      case 5: // Top
        setCameraPosition([0, 5, 0]);
        break;
      case 6: // Under
        setCameraPosition([0, -5, 0]);
        break;
      default:
        setCameraPosition([0, 0, 5]);
    }
  }, [camera]);

  useEffect(() => {
    const renderer = ref?.current?.canvas?.getBoundingClientRect()?.width
      ? new THREE.WebGLRenderer({ preserveDrawingBuffer: true })
      : null;

    if (renderer) {
      ref.current.setCanvas({ renderer });
    }
  }, [ref]);

  return (
    <div ref={ref} style={{ width, height, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <Canvas gl={{ preserveDrawingBuffer: true }} camera={{ position: cameraPosition }}>
        <ambientLight intensity={3} />
        <spotLight position={[10, 10, 10]} angle={0.15} penumbra={1} />
        <Model path={path} />
        <OrbitControls />
      </Canvas>
    </div>
  );
});

export default ModelViewer;
