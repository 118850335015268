import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';

const AdminNavbar = () => {
  return(
    <Navbar bg="light" data-bs-theme="light">
        <Container>
          <Navbar.Brand href="#home">Admin</Navbar.Brand>
          <Nav className="me-auto">
            <Nav.Link href="/">Home</Nav.Link>
            <Nav.Link href="/admin/sale">Sale</Nav.Link>
            <Nav.Link href="/admin/product">Product</Nav.Link>
            <Nav.Link href="/admin/manage">Manage</Nav.Link>
          </Nav>
        </Container>
      </Navbar>
  )
}

export default AdminNavbar;