import './Home.css';
import NavBar from './Component/NavBar.js';
import logob from './images/Logobrand.png'
import Footer from './Component/Footer.js';
import b1 from './images/b1.png'
import CarImage from './images/CAR.png'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Slider from "react-slick";
import ModelViewer from './3Drender';
import React, { useRef, useEffect, useState } from 'react';

function Home() {
  return (
    <div className="App">
      <NavBar/>
      <Container>
        <Row>
          <Col >
          <Container>
            <Row className='home-main-banner-block'>
              <Col sm={12} md={6}>
                  <div className='home-text-block'>
                    <h1>Welcome to the vanguard of precision and innovation.</h1>
                    <p> to 3DModels.biz.Here, we offer more than mere shapes—we provide the keys to unlock a universe where your grandest
                      visions take form. Every file, every model is a brushstroke in a masterpiece yet to be realized,
                        meticulously crafted to meet the needs of connoisseurs who understand that quality is an investment.
                    </p>
                    <button className='button-block'>Discover our product</button>
                  </div>
              </Col>
              <Col sm={12} md={4}>
                  <div className='home-image-block'>
                      <img className='img-block logo-show-block1'src={logob} alt='img-1'></img>
                  </div>
              </Col>
                <div >
                </div>
            </Row>
          </Container>
            </Col>
        </Row>
        <Row>
          <Col  md={12} lg={6}>
            <Container className='home-main-banner-block'>
              <Row>
                <Col  md={12} lg={6}>
                  <div className='home-text-block'>
                    <h2>At 3DModels.biz</h2>
                    <p>we don't just share designs; we deal in the currency of possibility.
                      The dreams of today's architects, game developers, and digital artists
                        are rendered in exquisite detail within our collections.
                    </p> 
                  </div>
                </Col>
                <Col  md={12} lg={6}>
                  <div className='home-image-block'>
                  <ModelViewer key='model1' path='assets/space_engineers_astronaut.glb' width='150px' height='250px' />
                  </div>
                </Col>
              </Row>
                </Container>
              </Col>
                <Col md={12} lg={6}>
                  <Container className='home-main-banner-block'>
                    <Row>
                      <Col md={12} lg={6}>
                        <div className='home-text-block'>
                          <h2>Your canvas awaits</h2>
                          <p>your tools stand ready. Begin the creation of tomorrow, 
                            today. Welcome to 3DModels.biz, where the art of the possible 
                            comes to life, one download at a time. Welcome, to where every detail counts.
                          </p>
                        </div>
                      </Col>
                      <Col md={12} lg={6}>
                        <div className='home-image-block'>
                          <img className='img-block'src={CarImage} alt='img-1'></img>
                        </div>
                      </Col>
                    </Row>
                  </Container>
                </Col>
        </Row>
        <Row>
          <Col>
          <Container>
            <Row  className='home-main-banner-block'>
              <Col sm={12} md={6}>
                <div className='home-image-block'>
                  <img className='img-block'src={CarImage} alt='img-1'></img>
                </div>
              </Col>
              <Col sm={12} md={6}>
                  <div className='home-text-block'>
                    <h2>Currency of Possibility</h2>
                    <p>More than shap, we provide the key to turn yourgrandest vision innovation
                        really. Every file, every model is a brushstroke in a masterprice yet to berealized
                        More than shap, we provide the key to turn yourgrandest vision innovation
                        really. Every file, every model is a brushstroke in a masterprice yet to berealized.
                        More than shap.
                    </p>
                    <button className='button-block'>Discover our product</button>
                  </div>
              </Col>
            </Row>
          </Container>
            </Col>
        </Row>
        <Row>
          <Col sm={7}>
          <div className='slider-block'>
          <Slider dots={true} infinite={true} speed={500}>
            <div>
              <Container className='home-main-banner-block carousel-box'>
                <Row>
                  <Col md={12} lg={6}>
                    <div className='home-text-block text_slide'>
                      <h2>Currency of Possibility</h2>
                    </div>
                  </Col>
                  <Col md={12} lg={6}>
                    <div className='home-image-block'>
                      <img className='img-block'src={CarImage} alt='img-1'></img>
                    </div>
                  </Col>
                </Row>
              </Container>
            </div>
            <div>
              <div  className='home-main-banner-block carousel-box'>
                <div className='home-text-block text_slide'>
                  <h2>Currency of Possibility</h2>
                </div>
                <div className='home-image-block'>
                  <img className='img-block'src={CarImage} alt='img-1'></img>
                </div>
              </div>
            </div>
          </Slider>
          </div>
            </Col>
            <Col sm={5}>
            <Row>
              <Col className='colum-block'>
              <Row className='home-main-banner-block small-box'>
                  <Col sm={12} md={6}>
                    <img className='img-block'src={CarImage} alt='img-1'></img>
                  </Col>
                  <Col sm={12} md={6}>
                    <div className='home-text-block'>
                      <h2>Currency of Possibility</h2>
                    </div>
                  </Col>
              </Row>
              </Col>
            </Row>
            <Row>
              <Col className='colum-block'>
                <Row className='home-main-banner-block small-box'>
                  <Col sm={12} md={6}>
                    <img className='img-block'src={CarImage} alt='img-1'></img>
                  </Col>
                  <Col sm={12} md={6}>
                    <div className='home-text-block'>
                      <h2>Currency of Possibility</h2>
                    </div>
                  </Col>
              </Row>
              </Col>
            </Row>
            </Col>
        </Row>
        <Row className='row-block'>
          <div className='category-block'>
            Category
          </div>
        </Row>
          <Row>
            <Col sm={12} md={6}>
              <div className='show-image-block'>
                <div className='show-text-image-block'>
                  <h1>Sanitary Ware Models</h1>
                </div>
              </div>
            </Col>
            <Col sm={12} md={6}>
              <div className='show-image-block'>
                <div className='show-text-image-block'>
                  <h1>Sanitary Ware Models</h1>
                </div>
              </div>
            </Col>
        </Row>
      </Container>
      <Footer/>
    </div>
  );
}

export default Home;
